import PropTypes from 'prop-types';
import { defaults, Line } from 'react-chartjs-2';

import { numberFormatter } from 'utils/numbers';

defaults.datasets.line.borderWidth = 4;
defaults.font.color = '#0c314f';
defaults.font.family = 'sofia-pro';
defaults.font.size = 16;
defaults.pointHitRadius = 20;
defaults.pointHoverRadius = 3;
defaults.pointRadius = 0;

const xTicksInterval = 20;

const options = {
  interaction: {
    intersect: false,
    mode: 'index',
  },
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'bottom',
      labels: { boxHeight: 1, boxWidth: 20, padding: 32 },
    },
    tooltip: {
      backgroundColor: '#f5f8fa',
      bodyColor: '#0c314f',
      borderColor: '#d4d8dc',
      borderWidth: 2,
      boxHeight: 1,
      boxPadding: 8,
      boxWidth: 20,
      callbacks: {
        title: (ctx) => `Month ${ctx[0].parsed?.x ?? ''}`,
        label: (ctx) =>
          `${ctx.dataset.label}: $${numberFormatter.format(
            Math.round(ctx.parsed.y * 1_000_000)
          )}`,
      },
      caretSize: 8,
      padding: 12,
      titleColor: '#0c314f',
    },
  },
  scales: {
    x: {
      grid: {
        color: (ctx) =>
          ctx.tick.value % xTicksInterval === 0 ? '#d4d8dc' : '#ffffff',
      },
      title: {
        display: true,
        text: 'Month',
      },
      ticks: {
        callback: function (val, index) {
          return index % xTicksInterval === 0 ? this.getLabelForValue(val) : '';
        },
        maxRotation: 0,
      },
    },
    y: {
      title: {
        display: true,
        text: '$Millions USD',
      },
      ticks: {
        maxTicksLimit: 4,
        stepSize: 2,
      },
    },
  },
};

function ChartCashflow({ data }) {
  return (
    <div className="chart-cashflow">
      <Line data={data} options={options} />
    </div>
  );
}

ChartCashflow.propTypes = {
  data: PropTypes.shape({
    labels: PropTypes.array.isRequired,
    datasets: PropTypes.array.isRequired,
  }).isRequired,
};

export { ChartCashflow };
