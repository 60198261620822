import { ErrorBoundary } from 'react-error-boundary';

import { AppHolding, ErrorFallback } from 'components';

function App() {
  return (
    <div className="relative">
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <AppHolding />
      </ErrorBoundary>
    </div>
  );
}

export default App;
