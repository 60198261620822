import {
  Button,
  Heading,
  LogoSoftIron,
  Paragraph,
  Wrapper,
} from '@softiron/design-system';

function AppHolding() {
  return (
    <div className="pv-XXL text-center">
      <Wrapper style={{ maxWidth: '55rem' }}>
        <LogoSoftIron className="mb-XXL" />
        <Heading level={1}>
          Rewriting the Rules for Cloud Cost of Ownership
        </Heading>
        <Paragraph size="medium">
          More of the same is not enough. Deep innovation, ground-up, systems
          centric thinking across the entire technology stack and supply chain
          has transformed the TCO of building and running clouds forever.
        </Paragraph>
        <Paragraph size="medium" className="mb-XL">
          Radical simplicity, game changing efficiency and true data
          sovereignty, SoftIron makes the products that underpin the next
          evolution of IT infrastructure.
        </Paragraph>
        <Button size="large" href="https://softiron.com/hypercloud/">
          Learn more about HyperCloud
        </Button>
      </Wrapper>
    </div>
  );
}

export { AppHolding };
