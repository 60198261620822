import { CURRENCY, LOCALE } from 'utils/constants/locale';

// FORMATTERS
export const currencyFormatter = new Intl.NumberFormat(LOCALE, {
  style: 'currency',
  currency: CURRENCY,
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const numberFormatter = new Intl.NumberFormat(LOCALE);

// UTILS
export function convertGBToBytes(value) {
  return value * Math.pow(1000, 3);
}

export function convertMillionsToDecimal(value) {
  return value / Math.pow(1000, 2);
}

export function formatCurrency(amount) {
  if (typeof amount === 'undefined') return '';
  return currencyFormatter.format(amount);
}

export function getSavingsPercentage(discountPrice, basePrice) {
  if (!discountPrice || !basePrice) return 0;
  const savingsPercentage = ((discountPrice - basePrice) / basePrice) * -100;
  return Math.ceil(savingsPercentage);
}

export function sum(values = []) {
  return values.reduce((total, value) => total + value, 0);
}
