import PropTypes from 'prop-types';

import { Button, Heading, Paragraph } from '@softiron/design-system';

import { IconAlertXL as IconAlert } from 'components';

function ErrorFallback({ resetErrorBoundary }) {
  return (
    <div className="pv-XXL text-center width-1-2-medium mh-auto" role="alert">
      <IconAlert />
      <Heading className="mt-L">Sorry, something went wrong</Heading>
      <Paragraph className="text-small">
        Looks like there could be an issue on our side. Please try again, and if
        the problem persists{' '}
        <a className="link" href="https://softiron.com/contact">
          contact us
        </a>
        .
      </Paragraph>
      <Button onClick={resetErrorBoundary}>Retry</Button>
    </div>
  );
}

ErrorFallback.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  resetErrorBoundary: PropTypes.func,
};

export { ErrorFallback };
