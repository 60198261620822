// TBC: I'm reluctant to import the whole icons library,
// as it's not properly tree-shaken, and adds excessive weight
// to the main JS bundle. We expect partners will want to embed
// this application on their own websites, so performance should
// be of fairly significant importance.

// Match @softiron/icons-library export names

function IconAddS() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      aria-hidden="true"
      focusable="false"
    >
      <path
        fill="#28A730"
        fillRule="evenodd"
        d="M13 7H9V3c0-.6-.4-1-1-1s-1 .4-1 1v4H3c-.6 0-1 .4-1 1s.4 1 1 1h4v4c0 .6.4 1 1 1s1-.4 1-1V9h4c.6 0 1-.4 1-1s-.4-1-1-1z"
        className="primary"
      ></path>
    </svg>
  );
}

function IconAlertXL() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      aria-hidden="true"
      focusable="false"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#00755B"
          d="M5.674 57h52.652L32 8.11 5.674 57zm-2.554.526l28-52a1 1 0 011.76 0l28 52A1 1 0 0160 59H4a1 1 0 01-.88-1.474z"
          className="secondary"
        ></path>
        <path
          fill="#28A730"
          d="M32 46a2 2 0 110 4 2 2 0 010-4zm1-20v15h-2V26h2z"
          className="primary"
        ></path>
      </g>
    </svg>
  );
}

function IconInfoS() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      aria-hidden="true"
      focusable="false"
    >
      <path
        fill="#28A730"
        fillRule="evenodd"
        d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"
        className="primary"
      ></path>
    </svg>
  );
}

function IconSubtractS() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      aria-hidden="true"
      focusable="false"
    >
      <path
        fill="#28A730"
        fillRule="evenodd"
        d="M2 7h12v2H2z"
        className="primary"
      ></path>
    </svg>
  );
}

IconAddS.displayName = 'IconAddS';
IconAlertXL.displayName = 'IconAlertXL';
IconInfoS.displayName = 'IconInfoS';
IconSubtractS.displayName = 'IconSubtractS';

export { IconAddS, IconAlertXL, IconInfoS, IconSubtractS };
